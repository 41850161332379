import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import SourceFlowForm from '@sourceflow-uk/sourceflowform';
import {
    Container,
} from 'reactstrap';
import styles from './styles.module.scss';

export default function SimpleForm({
    customClass = "",
    formId,
    successPageUrl,
}) {

    return (
        <>
            <div className={`${styles.root} ${customClass}`}>
                {formId ?
                    <SourceFlowForm
                        formId={formId}
                        onSubmitDone={() => (window.location.href = successPageUrl)}
                    /> : ""}
            </div>
        </>
    )
}